import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PortfolioLayout from '../components/portfolioLayout';

const Portfolio = () => {
    const data = useStaticQuery(graphql`
    query {
      files: allFile(
        filter: { sourceInstanceName: { eq: "portfolio" }, relativeDirectory: { eq: "construction" } },
        sort: { fields: [ name ] }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fixed (width: 400){
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return (
    <PortfolioLayout 
      title='New Construction'
      path='portfolio-construction'
      data={data.files.edges}
      link='/new-construction-contractor' />
)};

export default Portfolio;
