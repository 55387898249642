import React from "react";
import SEO from "../components/seo";
import ContactForm from "../components/contactForm";
import Layout from "../components/layout";
import Hr from "../components/hr";
import Header from "../components/header";
import PortfolioIndex from "../components/portfolioIndex";
import PortfolioSection from "../components/portfolioSection";
import { 
  Container, 
  Row,
  Col, 
} from "react-bootstrap";

const PortfolioLayout = ({title, path, data, link, beforeAfter}) => {
  return (
  <Layout>
    <SEO title={`${title} Portfolio`} path={path}/>
    <Header />
    
    <main id='main'>
      <PortfolioSection title={title} link={link} edges={data} beforeAfter={beforeAfter} className='cg-sectionLight' />
    </main>

    <div className='cg-sectionShaded'>
      <div className='cg-section'>
        <PortfolioIndex />
      </div>
    </div>
    
    <Hr/>
    
    <ContactForm/> 
    
      {/*
    <main id='main'>
      
      <Section id='roofing' title='Roofing' link='/roofing-contractor' edges={data.roofing.edges} className='cg-sectionShaded' />
      <Section id='siding' title='Siding' link='/vinyl-siding-contractor' edges={data.siding.edges} className='cg-sectionLight' />
      <Section id='kitchen' title='Kitchen' link='/kitchen-and-bathroom-contractor' edges={data.kitchen.edges} className='cg-sectionShaded' />
      <Section id='bathroom' title='Bathroom' link='/kitchen-and-bathroom-contractor' edges={data.bath.edges} className='cg-sectionLight' />
      <Section id='construction' title='New Construction' link='/new-construction-contractor' edges={data.construction.edges} className='cg-sectionShaded' />
      <Section id='carpentry' title='Carpentry' link='/carpentry-contractor' edges={data.carpentry.edges} className='cg-sectionLight' />
      <Section id='flooring' title='Flooring' edges={data.flooring.edges} className='cg-sectionShaded' />
      <Section id='other' title='Other' edges={data.other.edges} className='cg-sectionLight' />
      
    </main>
      */}
        
    <div id='footnotes' className='cg-sectionLight'>
      <div className='cg-section'>
        <Container fluid>
          <Row>
            <Col style={{ textAlign:'center', color:'#aaa'}}>
              <small>All construction photos are from MLB Contracting projects.</small>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    
  </Layout>
)};




export default PortfolioLayout;
