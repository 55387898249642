import React from "react";
import Hr from "../components/hr";
import SectionHeading from "../components/sectionHeading";
import { 
  Button, 
  Container, 
  Row, 
  Col, 
  Card,
  CardGroup,
} from "react-bootstrap";
import Img from "gatsby-image";

export default (props) => (
  <div id={props.id}  className={props.className}>
    <Container fluid>
      <Row>
        <Col>
          <SectionHeading>{props.title}</SectionHeading>
        </Col>
      </Row>
      {props.beforeAfter && props.beforeAfter.map((ba, key) =>
          <Row>
            <Col>
              <CardGroup style={{justifyContent:'space-evenly'}}>
                <Card style={{maxWidth:400, marginTop: '1rem'}}>
                  <Card.Header>Before</Card.Header>
                  <Img fluid={ba.before.childImageSharp.fluid} className="card-img"/>
                </Card>
                <Card style={{maxWidth:400, marginTop: '1rem'}}>
                  <Card.Header>After</Card.Header>
                  <Img fluid={ba.after.childImageSharp.fluid} className="card-img"/>
                </Card>
              </CardGroup>
            
            </Col>
          </Row>
        )
      } 
      <br/>
      <hr/>
      <br/>
      <Row>
        <Col className='cg-thumbnail-container'>
          {props.edges.map( (item,key) =>
            <div data-filename={item.node.name}> {/*data-file not used except to help in manually identifying photos*/}
              <Img key={item.node.id}
                  fixed={item.node.childImageSharp.fixed}
                  alt={`${props.title} image`}
                  className='card-img cg-picture cg-picture-portfolio cg-ratio-43'/>
                 {/*<div className='cg-caption'>{item.node.name}</div> {/* COMMENT OUT THIS LINE IN PRODUCTION TO REMOVE FILENAMES!!!*/}
            </div>
          )}
        </Col>
      </Row>
      <Row style={{textAlign:'center'}}>
        {props.link && 
          <Col sm style={{marginTop:'2rem'}}>
            <Button variant='secondary' href={props.link} >More {props.title} Info</Button>
          </Col>
        }
        <Col sm style={{marginTop:'2rem'}}>
          <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
        </Col>
      </Row>
    </Container>
    <Hr />
  </div>
)