import React from "react";
import {Link} from "gatsby";
import SectionHeading from "../components/sectionHeading";

const PortfolioIndex = () => {
    return (
    <div className='cg-portfolioIndex'>
        <SectionHeading>Portfolio</SectionHeading>

        <Link to='/portfolio-roofing' className='btn btn-danger'>Roofing</Link>
        <Link to='/portfolio-siding' className='btn btn-danger'>Siding</Link>
        <Link to='/portfolio-kitchen' className='btn btn-danger'>Kitchen</Link>
        <Link to='/portfolio-bathroom' className='btn btn-danger'>Bathroom</Link>
        <Link to='/portfolio-decks' className='btn btn-danger'>Decks</Link>
        <Link to='/portfolio-construction' className='btn btn-danger'>New Construction</Link>
        <Link to='/portfolio-carpentry' className='btn btn-danger'>Carpentry</Link>
      {/*
        <Button variant="outline-secondary" style={{margin:2}} href='#flooring'>Flooring</Button>
        <Button variant="outline-secondary" style={{margin:2}} href='#other'>Other</Button>
        */}
    </div>
    );
}

export default PortfolioIndex;